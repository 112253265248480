// Imports
// ------
import React from 'react';
import { Row } from '@waffl';
import { Label, GradientText } from '@tackl';

// Styles
// ------
import { Jacket, Title } from './styles';

// Component
// ------
const Hero = ({ title, label }) => (
	<Jacket padTop>
		{label &&
			<Label>
				<GradientText data-name={label}>
					{label}
				</GradientText>
			</Label>
		}
		<Title>{title ? title : `Welcome to the M—XR Blog`}</Title>
	</Jacket>
);

export default Hero;
