// Imports
// ------
import React from 'react';
import Tilt from 'react-tilt';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	Sweater,
	Content,
	Image,
	Caption,
	Title,
	TitleLink,
	New,
} from './styles';

// Component
// ------
const BlogFeatured = ({ content }) => {
	const { category, image, title, slug, date } = content;

	const url = `/blog/${slug}/`;

	const options = {
		scale: 1,
		max: 3,
		reverse: true,
	};

	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
			trigger: ({ node }) => {
				node.style.top = '0px';
			},
		},
	};

	return (
		<Jacket>
			<Tilt options={options}>
				<Row isExpanded sidePad>
					<Column small={12} mpad>
						<Sweater>
							<Content>
								<Caption category>{category.type}</Caption>

								<TitleLink to={url} {...pageTrans}>
									<Title weight='bold'>{title}</Title>
								</TitleLink>

								<Caption weight='bold'>
									Date: <span>{date.created}</span>
								</Caption>
							</Content>

							<Image to={url} {...pageTrans}>
								<New weight='bold'>
									<span>New</span>
								</New>

								<GatsbyImage
									image={image.gatsbyImageData}
									alt={image.alt ? image.alt : 'Featured'}
								/>
							</Image>
						</Sweater>
					</Column>
				</Row>
			</Tilt>
		</Jacket>
	);
};

export default BlogFeatured;
