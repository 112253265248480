// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Div, H4, Em } from '@tackl';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// ------
const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

export const PostWrapper = styled(Div)(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		width: 100%;
	`
);

export const Category = styled(Em)(
	(props) => css`
		margin: 0 0 2.4rem;
		font-weight: 300;
		font-size: 1.6rem;
	`
);

export const Date = styled(Em)(
	(props) => css`
		font-weight: 400;

		span {
			font-weight: ${props.theme.reg};
		}
	`
);

export const Image = styled(Div)(
	(props) => css`
		position: relative;
		width: 100%;
		aspect-ratio: 33/22;
		background: ${props.theme.bc3};
		border-radius: 2px;
		overflow: hidden;

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	`
);

export const Title = styled(H4)(
	(props) => css`
		margin: 2rem 0 3rem 0;
		width: 100%;
		font-size: clamp(2rem, 2vw, 3.4rem);
		font-weight: 400;
		line-height: 1.15em;
		text-overflow: ellipsis;
		transition: ${props.theme.ease};

		${breakup.medium`
			margin: 2.4rem 0 6rem 0;
		`}
	`
);

export const Jacket = styled(TransitionLink)(
	(props) => css`
		position: relative;
		display: block;
		z-index: 2;
		animation: ${fadeIn} 0.5s linear forwards;

		&.tilt,
		.tilt {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-flow: column;
			border-radius: 4px;

			background: #121212;
			color: ${props.theme.white};

			padding: 2.4rem;

			${breakup.large`
				padding: 3.6rem;
			`}
		}

		&:hover {
			${Title} {
				color: ${props.theme.bc1};
			}
		}
	`
);
