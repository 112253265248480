// Imports
// ------
import React, { useEffect, useState } from 'react';
import Button from '@parts/Button/Submit';
import BlogPost from './BlogPost';
import { Row, Column } from '@waffl';
import _ from 'lodash';

// Styles
// ------
import {
	Jacket,
	Filters,
	FiltersItem,
	SingleFilter,
	BlogList,
	LoadMore,
} from './styles';

// Component
// ------
const BlogListing = ({ items }) => {
	const [visible, setVisible] = useState(9);
	const [allItems, setAllItems] = useState([]);
	const [filter, setFilter] = useState('All');

	// On load, populate posts
	useEffect(() => {
		setAllItems(items);
	}, []);

	// Load more posts
	const loadMore = () => {
		setVisible(visible + 9);
	};

	// Filter Posts
	const handleFilter = (e) => {
		const filter = e.target.outerText;
		const filtered = _.filter(items, ['category.type', filter]);

		setFilter(filter);
		setAllItems(filtered);
	};

	// Show all posts
	const handleFilterAll = (e) => {
		setFilter(e.target.outerText);
		setAllItems(items);
	};

	return (
		<Jacket pad>
			<Row isExpanded sidePad>
				<Filters>
					<FiltersItem isActive={filter === 'All'}>
						<SingleFilter onClick={handleFilterAll}>All</SingleFilter>
					</FiltersItem>

					<FiltersItem isActive={filter === 'Announcement'}>
						<SingleFilter onClick={handleFilter}>Announcement</SingleFilter>
					</FiltersItem>

					<FiltersItem isActive={filter === 'News'}>
						<SingleFilter onClick={handleFilter}>News</SingleFilter>
					</FiltersItem>

					<FiltersItem isActive={filter === 'Community'}>
						<SingleFilter onClick={handleFilter}>Community</SingleFilter>
					</FiltersItem>

					<FiltersItem isActive={filter === 'Ideas'}>
						<SingleFilter onClick={handleFilter}>Ideas</SingleFilter>
					</FiltersItem>
				</Filters>
			</Row>

			<Row isExpanded sidePad>
				<Column mpad>
					<BlogList>
						{allItems.slice(0, visible).map((block, i) => (
							<BlogPost
								key={i}
								category={block.category.type}
								image={block.image}
								title={block.title}
								date={block.date.created}
								slug={block.slug}
							/>
						))}
					</BlogList>

					{visible < allItems.length && (
						<LoadMore>
							<Button onClick={loadMore} label='Load more' noIcon />
						</LoadMore>
					)}
				</Column>
			</Row>
		</Jacket>
	);
};

export default BlogListing;
