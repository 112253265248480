// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Div } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) =>
		css`
			background: linear-gradient(-90deg, #303339 -100%, #080809 50%);
		`
);

export const Filters = styled.ul(
	(props) => css`
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		padding: 0 2.4rem;
		overflow: scroll;
		margin: 0 0 3.6rem;

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			bottom: -2px;
			width: 2.4rem;
			background: ${props.theme.black};

			${breakup.large` display: none; `}
		}

		${breakup.large`
			border-bottom: 1px solid ${props.theme.white};
			margin: 0 1.2rem 6rem;
			padding: 0;
			overflow: hidden;
		`}
	`
);

export const SingleFilter = styled.button(
	(props) => css`
		${pStyles}
		background: transparent;
		padding: 2.4rem 2.4rem;
		transition: ${props.theme.ease};
		border-bottom: 1px solid ${props.theme.white};

		${breakup.large` border: none; `}
	`
);

export const FiltersItem = styled.li(
	(props) => css`
		transform: translateX(-2.4rem);

		color: ${props.isActive ? props.theme.bc1 : props.theme.white};

		&:hover {
			${SingleFilter} {
				${breakup.large`
					opacity: ${props.isActive ? 1 : 0.4};
					cursor: pointer;
				`}
			}
		}
	`
);

export const BlogList = styled(Div)(
	(props) => css`
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: 1fr;
		grid-gap: 2.4rem;

		.tilt {
			height: 100%;
		}

		${breakup.medium`
			grid-template-columns: repeat(2, 1fr);
		`}

		${breakup.large`
			grid-gap: 3.6rem;
			grid-template-columns: repeat(3, 1fr);
		`}

		${breakup.xlarge`
			grid-gap: 4.8rem;
		`}

		${breakup.xxlarge`
			grid-gap: 6rem;
		`}
	`
);

export const LoadMore = styled.div(
	(props) => css`
		margin: 6rem 0 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
	`
);
