// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) =>
		css`
			background: linear-gradient(-90deg, #303339 -100%, #080809 50%);
			padding-bottom: 6rem;
		`
);

export const Title = styled(H1)(
	(props) => css`
		text-align: center;
		width: 100%;
		max-width: 8em;
		margin: 0 auto;
		font-size: clamp(3.5rem, 6vw, 7rem);
		font-weight: 6w00;
		line-height: 1.1em;

		${breakup.medium`
			margin: 0 auto 4rem auto;
		`}
	`
);
