// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { SubmitJacket } from './styles';

// Component
// ------
const Button = ({ label, onClick, noIcon }) => {
	return (
		<SubmitJacket
			className={`ghost ${!noIcon && 'icon'}`}
			type='button'
			onClick={onClick}>
			<span>{label}</span>
			{!noIcon && <Icon type='chevron-right' />}
		</SubmitJacket>
	);
};

export default Button;
