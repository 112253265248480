// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/Blog';
import BlogFeatured from '@parts/BlogFeatured';
import BlogListing from '@parts/BlogListing';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Blog({ transitionStatus, entry, exit, data }) {
	const ts = transitionStatus;
	const bp = useBreakpoint();

	const { title, featured, seo, label } = data.page;

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo data={seo} backupData={data.globalSeo} />

			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero title={title} label={label} />
				<BlogFeatured content={featured} />
				<BlogListing items={data.posts.nodes} />
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Blog;

// GraphQL
// ------
export const query = graphql`
	query BlogListing {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		page: datoCmsBlogListing {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			label
			title
			featured: featuredPost {
				category {
					type: category
				}
				image: featuredImage {
					gatsbyImageData(
						width: 560
						placeholder: BLURRED
						forceBlurhash: false
						layout: FULL_WIDTH
					)
				}
				title
				slug
				date: meta {
					# created: publishedAt(formatString: "DD/MM/YYYY")
					# created: updatedAt(formatString: "DD/MM/YYYY")
					created: createdAt(formatString: "DD/MM/YYYY")
				}
			}
		}

		posts: allDatoCmsBlogPost(sort: { order: DESC, fields: meta___firstPublishedAt }) {
			nodes {
				title
				category {
					type: category
				}
				slug
				image: featuredImage {
					gatsbyImageData(
						width: 1440
						placeholder: BLURRED
						forceBlurhash: false
						layout: FULL_WIDTH
					)
					alt
					smartTags
				}
				date: meta {
					# created: publishedAt(formatString: "DD/MM/YYYY")
					# created: updatedAt(formatString: "DD/MM/YYYY")
					created: createdAt(formatString: "DD/MM/YYYY")
				}
			}
		}
	}
`;
