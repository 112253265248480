// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, grad, Section, Main, Div, Em, H3 } from '@tackl';
import TransitionLink from 'gatsby-plugin-transition-link';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: linear-gradient(-90deg, #303339 -100%, #080809 50%);
	`
);

export const Sweater = styled(Main)(
	(props) => css`
		position: relative;
		display: flex;
		flex-flow: column-reverse;
		border-radius: 4px;

		padding: 7.2rem 2.4rem 2.4rem;
		background: #121212;
		color: ${props.theme.white};

		&:hover {
			${breakup.large`
				${Title} {
					color: ${props.theme.bc1};
				}
			`}
		}

		${breakup.large`
            flex-flow: row;
            justify-content: space-between;
		    align-items: stretch;
            padding: 4.8rem 6rem;
        `}
	`
);

export const Content = styled(Div)(
	(props) => css`
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;

		${breakup.large`
            flex: 1;
            border-right: 1px solid ${props.theme.bc3};
            padding-right: 6rem;
        `}
	`
);

export const Caption = styled(Em)(
	(props) => css`
		${props.category &&
		css`
			position: absolute;
			top: 2.4rem;
			left: 2.4rem;
			font-weight: 300;
			font-size: 1.6rem;

			${breakup.large`
                position: relative;
                top: auto;
                left: auto;
            `}
		`}

		span {
			font-weight: ${props.theme.reg};
		}
	`
);

export const Title = styled(H3)(
	(props) => css`
		margin: 3.6rem 0;
		transition: ${props.theme.ease};
		font-weight: 400;
		font-size: clamp(2rem, 4vw, 4rem);
		line-height: 1.15em;

		${breakup.large`
            margin: 0;
        `}
	`
);

export const Image = styled(TransitionLink)(
	(props) => css`
		position: relative;
		display: flex;
		height: 18rem;
		background: ${props.theme.bc3};

		${breakup.large`
            flex: 1;
            margin: 2.4rem 0 2.4rem 6rem;
            height: 35rem;
        `}

		.gatsby-image-wrapper {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	`
);

export const TitleLink = styled(TransitionLink)(
	(props) =>
		css`
			&:hover {
				${breakup.large`
                    ${Title} {
                        color: ${props.theme.bc1};
                    }
                `}
			}
		`
);

export const New = styled(Em)(
	(props) => css`
		background-color: #3116ce;
		border-radius: 1.1rem;
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		transform: translate(1.2rem, -50%);

		padding: 1rem 2.5rem;

		${breakup.large`
            transform: translate(50%, -50%);
        `}

		span {
			color: ${props.theme.white};
			text-transform: none;
		}
	`
);

const livePulse = keyframes`
	from { opacity: 1; }
	to { opacity: 0.5; }
`;
