// Imports
// ------
import React from 'react';
import Tilt from 'react-tilt';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Category, Image, Title, Date, PostWrapper } from './styles';

// Component
// ------

const InnerContent = ({ category, image, title, date }) => (
	<PostWrapper>
		<div>
			<Category>{category}</Category>
			<Image>
				<GatsbyImage
					image={image.gatsbyImageData}
					alt={image.alt ? image.alt : 'Post Image'}
				/>
			</Image>
			<Title>{title}</Title>
		</div>
		<Date>
			Date: <span>{date}</span>
		</Date>
	</PostWrapper>
);

const BlogPost = ({ category, image, title, date, slug }) => {
	const bp = useBreakpoint();

	// Tilt Options
	const options = {
		scale: 0.95,
		max: 15,
		reverse: true,
	};

	// Limit characters and add an ellipse.
	const count = 60;
	if (title.length > count) title = title.substring(0, count) + '...';

	// Props for both mobile & Desktop
	const sharedProps = {
		category: category,
		image: image,
		title: title,
		date: date,
	};

	const pageTrans = {
		preventScrollJump: true,
		exit: {
			length: 1,
		},

		entry: {
			length: 1,
			trigger: ({ node }) => {
				node.style.top = '0px';
			},
		},
	};

	return (
		<Jacket
			{...pageTrans}
			className={bp.large ? `tilt` : ``}
			to={`/blog/${slug}/`}>
			{bp.large ? (
				<InnerContent {...sharedProps} />
			) : (
				<Tilt className='tilt' options={options}>
					<InnerContent {...sharedProps} />
				</Tilt>
			)}
		</Jacket>
	);
};

export default BlogPost;
